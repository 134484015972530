<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="7" lg="5">
        <b-card class="mt-5 border-0">
          <h2 class="mb-4">Login</h2>
          <validation-observer v-slot="{ handleSubmit }" ref="form">
            <b-form @submit.prevent="handleSubmit(loginUser)">
              <b-form-group
                label="Username"
                label-for="input-username"
                label-class="label-input"
              >
                <validation-provider v-slot="{ errors }" rules="required">
                  <b-form-input
                    id="input-username"
                    v-model="form.username"
                    placeholder="Enter username"
                    :state="errors[0] ? false : null"
                    autocomplete="off"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-username-live-feedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Password"
                label-for="input-password"
                label-class="label-input"
              >
                <validation-provider v-slot="{ errors }" rules="required">
                  <b-form-input
                    id="input-password"
                    v-model="form.password"
                    type="password"
                    placeholder="Enter password"
                    :state="errors[0] ? false : null"
                    autocomplete="off"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-password-live-feedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
              <b-button
                pill
                :disabled="loginInProgress"
                type="submit"
                class="mt-4"
                variant="outline-primary"
                >Submit</b-button
              >
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions } from "vuex";
import router from "@/router";

extend("required", {
  ...required,
  message: "Required field",
});

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      loginInProgress: false,
    };
  },
  created() {
    this.logout(false);
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    loginUser() {
      this.loginInProgress = true;
      const username = this.form.username;
      const password = this.form.password;
      this.login({ username, password }).then(() => {
        router.push({ name: "Contract" }).catch(() => {});
        this.loginInProgress = false;
      });
    },
  },
};
</script>