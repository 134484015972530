import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue } from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import VueMoment from 'vue-moment'
import Notifications from 'vue-notification'
import "@/scss/style.scss";


Vue.use(BootstrapVue)
Vue.use(VueMoment)
Vue.use(Notifications)
library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
