const ACCESS_TOKEN_ID = "access_token";
const REFRESH_TOKEN_ID = "refresh_token";

export const getAccessToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_ID);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_ID);
};

export const saveToken = (access_token, refresh_token) => {
  window.localStorage.setItem(ACCESS_TOKEN_ID, access_token);
  window.localStorage.setItem(REFRESH_TOKEN_ID, refresh_token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_ID);
  window.localStorage.removeItem(REFRESH_TOKEN_ID);
};

export default { getAccessToken, getRefreshToken, saveToken, destroyToken };
