import ApiService from '@/common/api.service'
import { documentUploadSuccessMessage, documentDeleteSuccessMessage } from "@/const";

const initialState = () => {
    return {
        document: {
            id: '',
            url: '',
            name: '',
        },
        documents: [],
    }
}

const state = initialState()

const getters = {
    document: (state) => state.document,
    documents: (state) => state.documents,
}

const actions = {
    async postDocument({ dispatch }, { formData }) {
        try {
            await ApiService.post('document', formData, { "Content-Type": "multipart/form-data" })
            dispatch('notification/successNotification', documentUploadSuccessMessage, { root: true })
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async getDocument({ commit, dispatch }, { documentId }) {
        try {
            commit('resetDocument')
            const response = await ApiService.get(`documents/${documentId}?signed_url=true`)
            const response2 = await ApiService.get('documents', {})
            commit('setDocuments', response2.data)
            commit('setDocument', response.data)
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async getDocuments({ commit, dispatch }) {
        try {
            const response = await ApiService.get('documents', {})
            commit('setDocuments', response.data)
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async updateDocumentType({ commit, dispatch }, { documentId, documentType }) {
        try {
            await ApiService.post(`documents/${documentId}/update?document_type=${documentType}`, {}, {})
            const response = await ApiService.get('documents', {})
            commit('setDocuments', response.data)
            dispatch('notification/successNotification', documentUploadSuccessMessage, { root: true })
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async deleteDocument({ commit, dispatch }, { documentId }) {
        try {
            await ApiService.post(`documents/${documentId}/delete`, {}, {})
            const response = await ApiService.get('documents', {})
            commit('setDocuments', response.data)
            dispatch('notification/successNotification', documentDeleteSuccessMessage, { root: true })
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    }
}

const mutations = {
    setDocument(state, data) {
        state.document.id = data.documentId
        state.document.url = data.documentUrl
        state.document.name = data.objectName.split('/').slice(-1)[0]
    },
    setDocuments(state, data) {
        state.documents = data.documents
    },
    resetDocument(state) {
        state.document = initialState().document
    },
}

export const documents = {
    namespaced: true,
    initialState,
    state,
    getters,
    actions,
    mutations
}