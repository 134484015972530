import Vue from "vue";
import Vuex from "vuex";
import { account } from './account.module'
import { accounting } from './accounting.module'
import { documents } from './documents.module'
import { contract } from './contract.module'
import { messages } from './messages.module'
import { notification } from './notification.module'

Vue.use(Vuex);

let initialState = {
  account: account.initialState(),
  accounting: accounting.initialState(),
  documents: documents.initialState(),
  contract: contract.initialState(),
  messages: messages.initialState(),
  notification: notification.initialState(),
}

export default new Vuex.Store({
  state: {},
  mutations: {
    resetStore(state) {
        Object.keys(state).forEach(key => {
            Object.assign(state[key], initialState[key])
        })
    }
  },
  actions: {},
  modules: {
    account,
    accounting,
    documents,
    contract,
    messages,
    notification,
  }
});
