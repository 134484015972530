export const NotificationDuration = 10000

export const documentUploadSuccessMessage = "Document submission successful"
export const documentDeleteSuccessMessage = "Document deletion successful"
export const accountingDocumentAccountsExtractionSuccessMessage = "Document processing successful"
export const contractFormFieldAlreadyCompletedMessage = "Field already completed"
export const contractFormFieldValueInsertedMessage = "Value inserted"
export const contractFormSaved = "Form saved"

// TODO: Move hardcoded values
export const contractFormInitialLanguage = "fr"
export const contractFormLanguages = ["en", "fr"]
export const contractFormBlabelTypes = ["New business", "Sections"]
export const contractFormBlabelsDisablingRules = {
  "Nature": [
    {value: "[EP]", toDisable: ["% of treaty cession"]},
    {value: "[QP]", toDisable: ["Amount per line", "Number of lines"]},
  ]
}
export const contractFormGroups = {
  "Management terms": {
    "type": "0",
    "isGroupCollapsed": true,
  },
  "Section details": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Acceptation terms": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Premium terms": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Commissions": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Profit sharing": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Reserves": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Claims": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Accounts and payments": {
    "type": "1",
    "isGroupCollapsed": false,
  },
  "Fees": {
    "type": "1",
    "isGroupCollapsed": false,
  }
}
export const contractLabelTr = {
  "Inception date": { "fr": "Date effet *", "en": "Inception date *" },
  "Expiry date": { "fr": "Date de fin", "en": "Expiry date" },
  "Retrocedant": { "fr": "Cédante *", "en": "Retrocedant *" },
  "Reinsured Country": { "fr": "Pays cédante", "en": "Reinsured Country" },
  "Broker": { "fr": "Apporteur", "en": "Broker" },
  "Currency": { "fr": "Devise", "en": "Currency" },
  "% of treaty cession": {
    "fr": "Partie réassurée",
    "en": "% of treaty cession"
  },
  "Order hereon basis": { "fr": "Base acceptation", "en": "Order hereon basis" },
  "Written participation": {
    "fr": "Parts souscrites *",
    "en": "Written participation *"
  },
  "Signed participation": { "fr": "Parts signées", "en": "Signed participation" },
  "Amount per line": { "fr": "Valeur du plein", "en": "Amount per line" },
  "Estimated Premium Income": {
    "fr": "Assiette de prime",
    "en": "Estimated Premium Income"
  },
  "Flat rate commission": {
    "fr": "Commission taux fixe",
    "en": "Flat rate commission"
  },
  "Profite commission rate": {
    "fr": "Taux de PB",
    "en": "Profite commission rate"
  },
  "Management expenses": {
    "fr": "Taux de frais généraux",
    "en": "Management expenses"
  },
  "Loss participation rate": {
    "fr": "Taux de PP",
    "en": "Loss participation rate"
  },
  "Loss reserve": { "fr": "Dépôts sinistres", "en": "Loss reserve" },
  "Premium reserve": { "fr": "Dépôts primes", "en": "Premium reserve" },
  "Claims advice": { "fr": "Avis sinistres", "en": "Claims advice" },
  "Cash loss limit": { "fr": "Réclamation sinistres", "en": "Cash loss limit" },
  "Premium reserve frequency": {
    "fr": "Périodicité des dépôts de primes",
    "en": "Premium reserve frequency"
  },
  "Account frequency": {
    "fr": "Périodicité des comptes",
    "en": "Account frequency"
  },
  "Number of lines": { "fr": "Pleins placés", "en": "Number of lines" },
  "Treaty limit": { "fr": "Montant de l'engagement", "en": "Treaty limit" },
  "Event limit": { "fr": "Limite par événement", "en": "Event limit" },
  "Provisional commission rate": {
    "fr": "Commission taux provisoire",
    "en": "Provisional commission rate"
  },
  "Scliding scale commission min rate": {
    "fr": "Commission échelle taux min",
    "en": "Scliding scale commission min rate"
  },
  "Scliding scale commission max rate": {
    "fr": "Commission échelle taux max",
    "en": "Scliding scale commission max rate"
  },
  "Scliding scale commission loss ratio min": {
    "fr": "Commission échelle S/P min",
    "en": "Scliding scale commission loss ratio min"
  },
  "Scliding scale commission loss ratio max": {
    "fr": "Commission échelle S/P max",
    "en": "Scliding scale commission loss ratio max"
  },
  "Overriding commission fixed rate": {
    "fr": "Sur commission taux fixe",
    "en": "Overriding commission fixed rate"
  },
  "Overriding commission provisional rate": {
    "fr": "Sur commission provisoire",
    "en": "Overriding commission provisional rate"
  },
  "Overriding commission Scliding scale min rate": {
    "fr": "Sur commission échelle taux min",
    "en": "Overriding commission Scliding scale min rate"
  },
  "Overriding commission Scliding scale max rate": {
    "fr": "Sur commission échelle taux max",
    "en": "Overriding commission Scliding scale max rate"
  },
  "Overriding commission Scliding scale loss ratio min": {
    "fr": "Sur commission échelle S/P min",
    "en": "Overriding commission Scliding scale loss ratio min"
  },
  "Overriding commission Scliding scale loss ratio max": {
    "fr": "Sur commission échelle S/P max",
    "en": "Overriding commission Scliding scale loss ratio max"
  },
  "Brokerage fee": { "fr": "Taux de courtage", "en": "Brokerage fee" },
  "Business tax": { "fr": "Taux de charge", "en": "Business tax" },
  "Section name": { "fr": "Nom de section", "en": "Section name" },
  "Nature": { "fr": "Nature *", "en": "Nature *" },
  "Class of business": { "fr": "Type de business *", "en": "Class of business *" },
  "Management terms": { "fr": "Conditions de gestion", "en": "Management terms" },
  "Section details": { "fr": "Détails des sections", "en": "Section details" },
  "Acceptation terms": {
    "fr": "Conditions acceptation",
    "en": "Acceptation terms"
  },
  "Premium terms": { "fr": "Conditions de primes", "en": "Premium terms" },
  "Commissions": { "fr": "Commissions", "en": "Commissions" },
  "Profit sharing": {
    "fr": "Participation aux résultats",
    "en": "Profit sharing"
  },
  "Reserves": { "fr": "Provisions et mouvements", "en": "Reserves" },
  "Claims": { "fr": "Sinistres", "en": "Claims" },
  "Accounts and payments": {
    "fr": "Comptes et règlements",
    "en": "Accounts and payments"
  },
  "Fees": { "fr": "Charges", "en": "Fees" }
}
