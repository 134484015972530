<template>
  <div>
    <nav-bar></nav-bar>
    <split id="split-panel">
      <split-area id="split-panel-left" :size="50" :minSize="600">
        <b-card class="my-4 ml-4 mr-2" border-variant="light">
          <div class="d-flex justify-content-between">
            <h5>Documents</h5>
            <h5 class="align-self-center">
              <font-awesome-icon
                v-if="!isSyncingDocuments"
                class="cursor-pointer pt-1"
                v-on:click="syncDocuments()"
                icon="sync-alt"
              />
              <b-spinner v-else variant="primary" label="Spinning"></b-spinner>
            </h5>
          </div>
          <b-form-file
            class="my-2"
            v-model="uploadedDocument"
            placeholder="Upload file"
            accept=".pdf"
          ></b-form-file>
          <b-button
            class="mt-2 mb-4"
            v-if="uploadedDocument"
            pill
            variant="outline-primary"
            v-on:click="uploadDocument()"
            >Submit</b-button
          >
          <b-list-group v-if="!isSyncingDocuments">
            <b-list-group-item
              href="#"
              class="flex-column align-items-start"
              v-for="(document, index) in documents"
              :key="index"
              v-on:click="getDocumentWithId(document.documentId)"
            >
              <div class="mb-1 d-flex justify-content-between">
                <h5 class="my-auto max-lines-1">
                  {{ document.objectName.split("/").slice(-1)[0] }}
                </h5>
                <b-dropdown variant="link" no-caret>
                  <template v-slot:button-content>
                    <font-awesome-icon class="text-dark" size="sm" icon="ellipsis-v" />
                  </template>
                  <b-dropdown-item
                    v-on:click.stop="deleteDocumentWithId(document.documentId)"
                    >Delete</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div class="mb-1 d-flex justify-content-between">
                <small class="max-lines-1">{{
                  document.documentCreatedOn
                }}</small>
                <small>
                  text detection: {{ document.textDetectionStatus }}</small
                >
              </div>
              <div class="d-flex justify-content-start">
                <b-form-select
                  v-if="!document.documentType"
                  class="w-25"
                  size="sm"
                  :value="null"
                  :options="documentTypeOptions"
                  v-on:change="
                    (type) =>
                      updateDocumentTypeWithId(document.documentId, type)
                  "
                >
                  <b-form-select-option :value="null"
                    >Document type</b-form-select-option
                  >
                </b-form-select>
                <span v-else
                  ><b-badge :class="`document-type-${document.documentType}`">{{
                    document.documentType.toUpperCase()
                  }}</b-badge></span
                >
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </split-area>
      <split-area id="split-panel-right" :size="50" :minSize="600">
        <b-card class="my-4 mr-4 ml-2" border-variant="light" no-body>
          <template v-if="document.id" #header>
            <span>{{ document.name }}</span>
          </template>
          <b-card-body id="pdf-viewer-scrollable" class="p-0">
            <pdf-viewer
              :src="document.url"
              :textLayerBlocks="[]"
              :textLayerBlockType="'WORD'"
              :selectedBlocksTags="[]"
            />
          </b-card-body>
        </b-card>
      </split-area>
    </split>
  </div>
</template>

<script>
import { Split, SplitArea } from "vue-split-panel";
import { mapGetters, mapActions } from "vuex";
import NavBar from "@/components/NavBar";
import PdfViewer from "@/components/PdfViewer";
import { Utils } from "@/common/utils";

function initialData() {
  return {
    now: new Date().toISOString(),
    isSyncingDocuments: false,
    uploadedDocument: null,
    documentTypeOptions: ["accounting", "contract"],
  };
}

export default {
  components: {
    Split,
    SplitArea,
    NavBar,
    PdfViewer,
  },
  created() {
    this.getDocuments();
  },
  data() {
    return initialData();
  },
  computed: {
    ...mapGetters("documents", ["document", "documents"]),
  },
  methods: {
    ...mapActions("documents", [
      "postDocument",
      "getDocument",
      "getDocuments",
      "updateDocumentType",
      "deleteDocument",
    ]),
    ...mapActions("notification", ["successNotification", "errorNotification"]),
    async syncDocuments() {
      this.isSyncingDocuments = true;
      await this.getDocuments();
      await Utils.pause(1000);
      this.isSyncingDocuments = false;
    },
    uploadDocument() {
      if (this.uploadedDocument) {
        let formData = new FormData();
        formData.append("pdf", this.uploadedDocument);
        this.postDocument({ formData });
        this.uploadedDocument = null;
      }
    },
    getDocumentWithId(documentId) {
      this.getDocument({ documentId });
      Object.assign(this.$data, initialData());
    },
    updateDocumentTypeWithId(documentId, documentType) {
      this.updateDocumentType({ documentId, documentType });
    },
    deleteDocumentWithId(documentId) {
      this.deleteDocument({ documentId });
    },
  },
};
</script>

<style scoped lang="scss">
#split-panel {
  height: 90vh;
  overflow: hidden;
}
#split-panel-right {
  display: flex;
  .card {
    display: flex;
    flex-direction: column;
    flex: 1;
    .card-header {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
    }
    .card-body {
      overflow: scroll;
    }
  }
}
.document-type-accounting {
  background-color: #3bbd99;
}
.document-type-contract {
  background-color: #98d1d8;
}
</style>
