import ApiService from '@/common/api.service'
import { accountingDocumentAccountsExtractionSuccessMessage } from '@/const.js' 

const initialState = () => {
    return {
        documents: [],
        document: {
            id: '',
            url: '',
            name: '',
            textDetection: [],
        },
        isProcessingDocument: false,
        accounts: [],
    }
}

const state = initialState()

const getters = {
    documents: (state) => state.documents,
    document: (state) => state.document,
    isProcessingDocument: (state) => state.isProcessingDocument,
    accounts: (state) => state.accounts,
}

const actions = {
    async getDocument({ commit, dispatch }, { documentId }) {
        try {
            commit('resetDocument')
            commit('resetAccounts')
            const response = await ApiService.get(`documents/${documentId}?signed_url=true`)
            const response2 = await ApiService.get('documents', {})
            commit('setDocuments', response2.data)
            commit('setDocument', response.data)
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async getDocuments({ commit, dispatch }) {
        try {
            const response = await ApiService.get('documents', {})
            commit('setDocuments', response.data)
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async extractDocumentAccounts({ commit, dispatch }, { documentId }) {
        try {
            commit('setIsProcessingDocument', true)
            const response = await ApiService.post(`accounting/account-recognition?document_id=${documentId}`, {}, {})
            commit('setAccounts', response.data)
            commit('setIsProcessingDocument', false)
            dispatch('notification/successNotification', accountingDocumentAccountsExtractionSuccessMessage, { root: true })
        } catch (error) {
            commit('setIsProcessingDocument', false)
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
}

const mutations = {
    setDocument(state, data) {
        state.document.id = data.documentId
        state.document.url = data.documentUrl
        state.document.name = data.objectName.split('/').slice(-1)[0]
        state.document.textDetection = data.textDetection ? data.textDetection.blocks : []
    },
    setDocuments(state, data) {
        state.documents = data.documents
    },
    setIsProcessingDocument(state, data) {
        state.isProcessingDocument = data
    },
    setAccounts(state, data) {
        state.accounts = data.accounts
    },
    resetDocument(state) {
        state.document = initialState().document
    },
    resetAccounts(state) {
        state.accounts = initialState().accounts;
        state.accounts = [ ...state.accounts ];
    }
}

export const accounting = {
    namespaced: true,
    initialState,
    state,
    getters,
    actions,
    mutations
}