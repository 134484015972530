<template>
  <div>
    <nav-bar />
    <b-sidebar
      width="550px"
      backdrop-variant="dark"
      v-model="isSideMenuOpen"
      backdrop
      shadow
    >
      <b-card
        class="m-4"
        border-variant="light"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5>Documents</h5>
          <h5 class="align-self-center">
            <font-awesome-icon
              v-if="!isSyncingDocuments"
              class="cursor-pointer pt-1"
              v-on:click="syncDocuments()"
              icon="sync-alt"
            />
            <b-spinner
              v-else
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </h5>
        </div>
        <b-form-file
          class="my-2"
          v-model="uploadedDocument"
          placeholder="Upload file"
          accept=".pdf"
        ></b-form-file>
        <b-button
          class="mt-2 mb-4"
          v-if="uploadedDocument"
          pill
          variant="outline-primary"
          v-on:click="uploadDocument()"
        >Submit</b-button>
        <b-form-select
          class="w-100 mb-4"
          v-model="documentsFilterValue"
          :options="['All', 'SUCCEEDED', 'IN_PROGRESS', 'FAILED']"
        ></b-form-select>
        <b-list-group v-if="!isSyncingDocuments">
          <b-list-group-item
            href="#"
            class="flex-column align-items-start"
            v-for="(document, index) in filteredDocuments"
            :key="index"
            v-on:click="getDocumentWithId(document.documentId)"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 max-lines-1">
                {{ document.objectName.split("/").slice(-1)[0] }}
              </h5>
              <div>
                <b-dropdown
                  no-caret
                  variant="link"
                  toggle-class="text-decoration-none text-dark p-0"
                >
                  <template v-slot:button-content>
                    <font-awesome-icon
                      size="sm"
                      icon="chevron-down"
                    />
                  </template>
                  <b-dropdown-item
                    v-on:click.stop="deleteDocumentWithId(document.documentId)"
                    href="#"
                  >Delete</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div v-if="document.rootUserId">
              <small>[{{ document.rootUserId }}]</small>
            </div>
            <div
              v-if="document.textEntitiesPushedOn"
              class="d-flex w-100 justify-content-between"
            >
              <small>{{ document.textEntitiesPushedOn.slice(0, -7) }}</small>
              <small>PUSHED</small>
            </div>
            <div class="d-flex w-100 justify-content-between">
              <small class="max-lines-1">{{
                document.documentCreatedOn.slice(0, -7)
              }}</small>
              <small>{{
                getDocumentStatus(
                  document.textDetectionStatus,
                  document.textEntitiesId
                )
              }}</small>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-sidebar>
    <split id="split-panel">
      <split-area
        id="split-panel-left"
        :size="50"
        :minSize="600"
      >
        <b-card
          class="m-2"
          border-variant="light"
          no-body
        >
          <template #header>
            <div class="d-flex justify-content-between">
              <span
                class="font-weight-bold cursor-pointer"
                v-on:click="isSideMenuOpen = !isSideMenuOpen"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="folder"
                />Documents
              </span>
              <span v-if="document.id">{{ document.name }}</span>
            </div>
          </template>
          <b-card-body
            id="pdf-viewer-scrollable"
            class="p-0"
          >
            <pdf-viewer
              :src="document.url"
              :textLayerBlocks="document.textDetection"
              :textLayerBlockType="'WORD'"
              :selectedBlocksTags="groupedFormKeys()"
              v-on:onAddSelectedBlocks="
                (blocks, tag) => addDocumentSelectedBlocksToForm(blocks, tag)
              "
            />
          </b-card-body>
        </b-card>
      </split-area>
      <split-area
        id="split-panel-right"
        :size="50"
        :minSize="600"
      >
        <b-card
          v-if="Object.keys(form).length != 0"
          class="m-2 sticky-top"
          border-variant="light"
        >
          <div class="d-flex align-items-center justify-content-between mb-3">
            <b-form-select
              class="w-25"
              v-model="formLanguage"
              :options="formLanguages"
            ></b-form-select>
            <div>
              <small class="mr-2">Last checkpoint:
                {{ formLastCheckpoint | moment("MM/DD/YYYY hh:mm") }}</small>
              <b-button
                v-on:click="putDocumentEntitiesWithId(document.id)"
                pill
                variant="outline-primary"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  icon="save"
                />
              </b-button>
              <!-- <b-button
                class="ml-1"
                v-on:click="exportResults"
                pill
                variant="outline-primary"
                ><font-awesome-icon class="cursor-pointer" icon="download"
              /></b-button> -->
              <b-button
                class="ml-1"
                v-if="formCompletionValue / formCompletionMax > 0"
                v-on:click="pushResults"
                pill
                variant="outline-primary"
              >
                <font-awesome-icon
                  class="cursor-pointer"
                  icon="check"
                />
              </b-button>
            </div>
          </div>
          <b-progress :max="formCompletionMax">
            <b-progress-bar
              :value="formCompletionValue"
              :label="`${(
                (formCompletionValue / formCompletionMax) *
                100
              ).toFixed(0)}%`"
            ></b-progress-bar>
          </b-progress>
        </b-card>
        <b-card
          class="m-2"
          border-variant="light"
          no-body
        >
          <b-card-body class="p-0">
            <b-tabs
              pills
              card
            >
              <b-tab
                title="Analyse"
                v-if="Object.keys(form).length == 0"
              >
                <b-container
                  class="px-0"
                  fluid
                >
                  <b-row
                    class="text-center"
                    v-if="isProcessingDocument || isLoadingDocument"
                  >
                    <b-col
                      lg="12"
                      align-self="center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Spinning"
                      ></b-spinner>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <template v-for="(blabelType, t) in formBlabelTypes">
                <b-tab
                  :title="blabelType"
                  v-if="Object.keys(form).length != 0"
                  :key="t"
                >
                  <b-container
                    class="px-0"
                    fluid
                  >
                    <b-row v-if="!isProcessingDocument && t == 1">
                      <b-col
                        class="mb-3"
                        md="6"
                        lg="4"
                      >
                        <b-form-group
                          label="# Layers"
                          label-for="layers"
                          label-class="label-input"
                        >
                          <b-input-group>
                            <b-form-input
                              id="layers"
                              :value="numLayers"
                              v-on:upadate="setNumLayers"
                              readonly
                            ></b-form-input>
                            <b-input-group-prepend>
                              <b-button-group>
                                <b-button
                                  variant="outline-primary"
                                  class="text-secondary border-0"
                                  v-on:click="
                                    () =>
                                      numLayers > 1
                                        ? setNumLayers(numLayers - 1)
                                        : ''
                                  "
                                >
                                  <font-awesome-icon
                                    class="cursor-pointer pt-1"
                                    icon="minus"
                                  />
                                </b-button>
                                <b-button
                                  variant="outline-primary"
                                  class="text-secondary border-0"
                                  v-on:click="
                                    () =>
                                      numLayers < 12
                                        ? setNumLayers(numLayers + 1)
                                        : ''
                                  "
                                >
                                  <font-awesome-icon
                                    class="cursor-pointer pt-1"
                                    icon="plus"
                                  />
                                </b-button>
                              </b-button-group>
                            </b-input-group-prepend>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div
                      v-for="(group, g) in filterFormGroupWithType(t)"
                      :key="g"
                    >
                      <h5
                        class="mb-4 cursor-pointer"
                        v-on:click="
                          inputGroups[group].isGroupCollapsed =
                            !inputGroups[group].isGroupCollapsed
                        "
                      >
                        {{ tr(group, formLanguage) }}
                        <font-awesome-icon
                          size="sm"
                          :icon="
                            inputGroups[group].isGroupCollapsed
                              ? 'chevron-up'
                              : 'chevron-down'
                          "
                        />
                      </h5>
                      <b-collapse
                        v-model="inputGroups[group].isGroupCollapsed"
                        :id="`collapse-group-${labelToId(group)}`"
                      >
                        <b-row
                          class="mb-3"
                          v-for="(
                            blabel, i
                          ) in filterFormWithBlabelTypeAndGroup(t, group)"
                          :key="i"
                        >
                          <b-col lg="12">
                            <label class="d-block label-input">{{ tr(blabel, formLanguage) }}
                            </label>
                          </b-col>
                          <b-col
                            :lg="t == 1 ? 3 : 6"
                            v-for="(_, j) in form[blabel].blabelType == 1
                              ? numLayers
                              : 1"
                            :key="j"
                          >
                            <b-form-group :disabled="form[blabel].newValue[j].class === 'disabled'">
                              <b-input-group v-if="!form[blabel].newValue[j].isResult">
                                <b-form-input
                                  class="cursor-text"
                                  v-on:focus="formInputOnFocus(blabel, j)"
                                  v-on:click="documentScrollToId(
                                    form[blabel].newValue[j].blockIds
                                  )"
                                  type="url"
                                  autocomplete="off"
                                  :value="form[blabel].newValue[j].text"
                                  v-on:update="
                                    (text) =>
                                      setFormNewValue({
                                        blabel,
                                        idx: j,
                                        text,
                                        blockIds: [],
                                        isResult: false,
                                        class: null,
                                      })
                                  "
                                  :placeholder="form[blabel].placeHolder"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    v-on:click="
                                      showTranscoControlModal(
                                        form[blabel].label,
                                        blabel,
                                        j,
                                        form[blabel].newValue[j].text,
                                        form[blabel].newValue[j].blockIds,
                                        form[blabel].newValue[j].class
                                      )
                                    "
                                    variant="outline-primary"
                                    class="text-secondary border-0"
                                  >
                                    <font-awesome-icon
                                      :class="
                                        form[blabel].newValue[j].class || ''
                                      "
                                      class="cursor-pointer pt-1"
                                      icon="check"
                                    />
                                  </b-button>
                                </b-input-group-append>
                                <!-- <b-form-invalid-feedback
                                  :state="formInputValidators[blabel][j]"
                                  >Invalid. Correct value: <br />
                                  {{ form[blabel].placeHolder }}
                                </b-form-invalid-feedback> -->
                              </b-input-group>
                              <h5
                                class="my-1"
                                v-else
                              >
                                <b-badge
                                  class="
                                    cursor-pointer
                                    font-weight-normal
                                    py-2
                                    px-0
                                    bg-white
                                  "
                                  :class="
                                    numLayers > 1
                                      ? 'mw-100 max-lines-1 mr-3'
                                      : ''
                                  "
                                  v-on:click="
                                    documentScrollToId(
                                      form[blabel].newValue[j].blockIds
                                    )
                                  "
                                  :title="form[blabel].newValue[j].text"
                                >
                                  <span class="mx-1 text-dark">
                                    <b-button
                                      variant="outline-primary"
                                      class="text-secondary border-0 mr-1"
                                      v-on:focus="
                                        documentScrollToId(
                                          form[blabel].newValue[j].blockIds
                                        )
                                      "
                                      v-on:click="
                                        setFormNewValue({
                                          blabel,
                                          idx: j,
                                          text: form[blabel].newValue[
                                            j
                                          ].text.replace(/\[(.*?)\]$/, ''),
                                          blockIds:
                                            form[blabel].newValue[j].blockIds,
                                          isResult: false,
                                          class: form[blabel].newValue[j].class,
                                        })
                                      "
                                    >
                                      <font-awesome-icon
                                        size="sm"
                                        class="cursor-pointer"
                                        icon="times"
                                      />
                                    </b-button>{{ form[blabel].newValue[j].text }}
                                  </span>
                                </b-badge>
                              </h5>
                            </b-form-group>
                          </b-col>
                          <b-col lg="12">
                            <b-collapse
                              accordion="accordion"
                              v-if="formInputNumValuesLeft[blabel] > 0"
                              v-model="form[blabel].isInputCollapsed"
                              :id="`collapse-${labelToId(blabel)}`"
                            >
                              <b-card
                                class="border-0"
                                no-body
                              >
                                <b-card-body class="p-0">
                                  <b-list-group
                                    class="overflow-auto"
                                    horizontal="md"
                                  >
                                    <b-list-group-item
                                      v-for="(elem, i) in form[blabel]
                                        .isInputCandidatesCollapsed
                                        ? form[blabel].candidates
                                        : form[blabel].candidates.slice(0, 5)"
                                      :key="i"
                                      class="
                                        d-flex
                                        cursor-pointer
                                        align-items-center
                                        p-1
                                      "
                                      v-on:click="
                                        documentScrollToId(elem.blockIds)
                                      "
                                    >
                                      <b-button
                                        variant="outline-primary"
                                        v-on:focus="documentScrollToId(elem.blockIds)"
                                        class="
                                          text-secondary
                                          cursor-pointer
                                          border-0
                                          max-lines-1
                                        "
                                        v-on:click="
                                          setFormNewValue({
                                            blabel,
                                            idx: formInputOnFocusIdx,
                                            text: elem.text,
                                            blockIds: elem.blockIds,
                                            isResult: false,
                                            class:
                                              documentEntitySimilarityClass(
                                                elem
                                                  .businessLabelsContextMaxSimilarity[
                                                  blabel
                                                ]
                                              ),
                                          })
                                        "
                                      >
                                        <font-awesome-icon
                                          class="pt-1"
                                          icon="plus-circle"
                                          :class="
                                            documentEntitySimilarityClass(
                                              elem
                                                .businessLabelsContextMaxSimilarity[
                                                blabel
                                              ]
                                            )
                                          "
                                        />
                                        <span class="text-nowrap ml-2">{{
                                        elem.text
                                        }}</span>
                                      </b-button>
                                    </b-list-group-item>
                                    <b-list-group-item
                                      v-if="form[blabel].candidates.length > 5"
                                      class="
                                        d-flex
                                        cursor-pointer
                                        align-items-center
                                        p-1
                                      "
                                    >
                                      <b-button
                                        variant="outline-primary"
                                        class="
                                          cursor-pointer
                                          border-0
                                          max-lines-1
                                        "
                                        v-on:click="
                                            form[
                                              blabel
                                            ].isInputCandidatesCollapsed =
                                              !form[blabel]
                                                .isInputCandidatesCollapsed
                                        "
                                      >
                                        <span class="font-weight-bold font-size-sm">{{
                                            form[blabel]
                                              .isInputCandidatesCollapsed
                                              ? "show less"
                                              : "show more"
                                          }}</span>
                                      </b-button>
                                    </b-list-group-item>
                                  </b-list-group>
                                </b-card-body>
                              </b-card>
                            </b-collapse>
                          </b-col>
                        </b-row>
                      </b-collapse>
                    </div>
                  </b-container>
                </b-tab>
              </template>
              <b-modal
                :hide-footer="true"
                ref="control-modal"
                title="Control"
              >
                <h5 class="my-4">{{ modalTranscoControlInfo.text }}</h5>
                <b-list-group>
                  <b-list-group-item
                    v-for="(
                      suggestion, index
                    ) in currentTranscoControl.suggestions"
                    :key="index"
                    class="cursor-pointer"
                    v-on:click="
                      selectTranscoControlSuggestion(suggestion.value)
                    "
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <b-button
                        variant="outline-primary"
                        class="text-secondary
                              text-left
                              cursor-pointer
                              border-0
                            "
                      >
                        <span>{{ suggestion.value ? suggestion.value : `\" \"` }}
                          {{
                          suggestion.text ? `(${suggestion.text})` : ""
                        }}</span>
                      </b-button>
                      <span class="text-nowrap">(sim:
                        {{
                          suggestion.similarity.toString().slice(0, 4)
                        }})</span>
                    </div>
                    <small
                      v-if="suggestion.controlError"
                      class="text-danger"
                    >{{ suggestion.controlError[0] }}
                    </small>

                  </b-list-group-item>
                  <b-list-group-item>
                    <b-input-group>
                      <b-form-input v-model="modalTranscoControlInputValue" />
                      <b-input-group-append v-if="modalTranscoControlInputValue">
                        <b-button
                          v-on:click="
                            modalTranscoControlInputValue
                              ? selectTranscoControlSuggestion(
                                  modalTranscoControlInputValue
                                )
                              : ''
                          "
                          variant="outline-primary"
                          class="text-secondary border-0"
                        >
                          <font-awesome-icon
                            class="cursor-pointer pt-1"
                            icon="check"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
              <!-- <b-tab
                title="Controls"
                v-if="Object.keys(form).length != 0 && isFormValidated"
              >
                <div v-if="!isControllingEntities">
                  <b-form-select
                    class="w-25 mb-4"
                    v-model="controlledEntitiesFilterValue"
                    :options="[
                      { value: 0, text: 'All' },
                      { value: 1, text: 'Errored rows only' },
                      { value: 2, text: 'Non-empty rows only' },
                    ]"
                  ></b-form-select>
                  <template
                    v-for="(layerEntities, layer) in filteredControlledEntities"
                  >
                    <h5 :key="`a-${layer}`">Layer {{ layer + 1 }}</h5>
                    <b-table
                      striped
                      :items="layerEntities"
                      thead-class="d-none"
                      :key="`b-${layer}`"
                    >
                      <template #cell(label)="data">
                        <div class="font-weight-bold">
                          {{ tr(data.value, formLanguage) }}
                        </div>
                      </template>
                      <template #cell(value)="data">
                        <div contenteditable>{{ data.value }}</div>
                      </template>
                      <template #cell(error)="data">
                        <small
                          v-for="(error, index) in data.value"
                          class="text-danger"
                          :key="index"
                          >{{ error }}</small
                        >
                      </template>
                    </b-table>
                  </template>
                </div>
                <div class="d-flex justify-content-center" v-else>
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </div>
              </b-tab> -->
            </b-tabs>
          </b-card-body>
        </b-card>
      </split-area>
    </split>
  </div>
</template>

<script>
import { Split, SplitArea } from "vue-split-panel";
import animateScrollTo from "animated-scroll-to";
import { mapGetters, mapActions, mapMutations } from "vuex";
import NavBar from "@/components/NavBar";
import PdfViewer from "@/components/PdfViewer";
import {
  contractFormBlabelTypes,
  contractFormInitialLanguage,
  contractFormLanguages,
  contractFormFieldAlreadyCompletedMessage,
  contractFormFieldValueInsertedMessage,
  contractFormBlabelsDisablingRules,
  contractFormGroups,
} from "@/const";
import { Utils, contractUtils } from "@/common/utils";

function initialData() {
  return {
    now: new Date().toISOString(),
    isSideMenuOpen: false,
    isSyncingDocuments: false,
    uploadedDocument: null,
    formInputOnFocusIdx: null,
    formBlabelTypes: contractFormBlabelTypes,
    formLanguage: contractFormInitialLanguage,
    formLanguages: contractFormLanguages,
    formLastCheckpoint: new Date().toISOString(),
    inputGroups: JSON.parse(JSON.stringify(contractFormGroups)),
    documentsFilterValue: "All",
    modalTranscoControlInfo: {},
    modalTranscoControlInputValue: "",
  };
}

export default {
  components: {
    Split,
    SplitArea,
    NavBar,
    PdfViewer,
  },
  async created() {
    await this.getDocuments();
    if (this.documents.length > 0) {
      const documentId = this.documents[0]["documentId"];
      this.getDocument({ documentId });
    }
  },
  data() {
    return initialData();
  },
  computed: {
    ...mapGetters("contract", [
      "document",
      "documents",
      "form",
      "numLayers",
      "isProcessingDocument",
      "isLoadingDocument",
      "currentTranscoControl",
    ]),
    formInputNumValuesLeft() {
      let formInputNumValuesLeft = {};
      Object.keys(this.form).map(
        (key) =>
          (formInputNumValuesLeft[key] =
            (this.form[key].blabelType == 0 ? 1 : this.numLayers) -
            this.form[key].newValue.filter((nv) => nv.isResult).length)
      );
      return formInputNumValuesLeft;
    },
    formCompletionValue() {
      let formInputNumValuesLeftCum = 0;
      Object.keys(this.formInputNumValuesLeft).map(
        (key) => (formInputNumValuesLeftCum += this.formInputNumValuesLeft[key])
      );
      return 5 + 29 * this.numLayers - formInputNumValuesLeftCum;
    },
    formCompletionMax() {
      return 5 + 29 * this.numLayers;
    },
    filteredDocuments() {
      return this.documents.filter((e) => this.documentsFilter(e));
    },
  },
  watch: {
    form: {
      handler(newForm) {
        if (Object.keys(newForm).length > 0)
          Object.keys(contractFormBlabelsDisablingRules).map((ruleKey) => {
            if (ruleKey in newForm)
              newForm[ruleKey].newValue.map((value, i) => {
                contractFormBlabelsDisablingRules[ruleKey].map((rule) => {
                  if (value.text.endsWith(rule.value) && value.isResult) {
                    rule.toDisable.map((blabel) => {
                      newForm[blabel].newValue[i] = {
                        text: "",
                        blockIds: [],
                        isResult: false,
                        class: "disabled",
                      };
                    });
                  } else {
                    rule.toDisable.map((blabel) => {
                      newForm[blabel].newValue[i].class = "";
                    });
                  }
                });
              });
          });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("contract", [
      "postDocument",
      "getDocument",
      "getDocuments",
      "deleteDocument",
      "putDocumentEntities",
      "pushDocumentEntities",
      "documentEntityTranscoControl",
    ]),
    ...mapActions("notification", ["successNotification", "errorNotification"]),
    ...mapMutations("contract", [
      "setFormNewValue",
      "setFormIsInputCollapsed",
      "setNumLayers",
    ]),
    filterFormGroupWithType(type) {
      return Object.keys(this.inputGroups).filter(
        (l) => this.inputGroups[l].type == type
      );
    },
    filterFormWithGroup(group) {
      return Object.keys(this.form).filter(
        (key) => this.form[key].group == group
      );
    },
    filterFormWithBlabelTypeAndGroup(blabelType, group) {
      return Object.keys(this.form).filter(
        (key) =>
          this.form[key].blabelType == blabelType &&
          this.form[key].group == group
      );
    },
    formInputOnFocus(blabel, idx) {
      this.documentScrollToId(this.form[blabel].newValue[idx].blockIds);
      this.formInputOnFocusIdx = idx;
      this.setFormIsInputCollapsed({ blabel, collapseState: true });
    },
    addDocumentSelectedBlocksToForm(blocks, tag) {
      let blabel = tag;
      let numValuesLeft = this.formInputNumValuesLeft[blabel];
      if (numValuesLeft < 1) {
        this.errorNotification(contractFormFieldAlreadyCompletedMessage);
        return;
      }
      let idx = this.form[blabel].newValue.findIndex(
        (nv) => nv.isResult == false
      );
      let blockIds = [];
      let text = "";
      blocks.map((block) => {
        blockIds.push(block.blockId);
        text = text + ` ${block.text}`;
      });
      this.setFormNewValue({
        blabel,
        idx,
        text,
        blockIds,
        isResult: false,
        class: null,
      });
      this.successNotification(contractFormFieldValueInsertedMessage);
    },
    async putDocumentEntitiesWithId(documentId) {
      await this.putDocumentEntities({ documentId });
      this.formLastCheckpoint = new Date().toISOString();
    },
    uploadDocument() {
      if (this.uploadedDocument) {
        let formData = new FormData();
        formData.append("pdf", this.uploadedDocument);
        this.postDocument({ formData });
        this.uploadedDocument = null;
      }
    },
    getDocumentWithId(documentId) {
      this.getDocument({ documentId });
      Object.assign(this.$data, initialData());
    },
    deleteDocumentWithId(documentId) {
      this.deleteDocument({ documentId });
    },
    async syncDocuments() {
      this.isSyncingDocuments = true;
      await this.getDocuments();
      await Utils.pause(1000);
      this.isSyncingDocuments = false;
    },
    getDocumentStatus(textDetectionStatus, entitiesExtractionId) {
      if (
        textDetectionStatus == "IN_PROGRESS" ||
        (textDetectionStatus == "SUCCEEDED" &&
          entitiesExtractionId === undefined)
      )
        return "IN_PROGRESS";
      if (textDetectionStatus == "SUCCEEDED" && entitiesExtractionId)
        return "SUCCEEDED";
      return "FAILED";
    },
    labelToId(label) {
      return label.toLowerCase().replaceAll(" ", "-");
    },
    async documentScrollToId(ids) {
      if (ids.length == 0) return;
      let top = document.querySelector(`#block-${ids[0]}`).dataset.offsetTop;

      if (top) {
        const options = {
          cancelOnUserAction: false,
          elementToScroll: document.querySelector("#pdf-viewer-scrollable"),
          maxDuration: 2000,
          speed: 200,
          verticalOffset: -100,
        };
        await animateScrollTo(Math.floor(top), options);
      }
      for (let i = 0; i < ids.length; i++) {
        document
          .querySelector(`#block-${ids[i]}`)
          .setAttribute("style", "animation: block-blinker 4s;");
        setTimeout(
          () =>
            document.querySelector(`#block-${ids[i]}`).removeAttribute("style"),
          2000
        );
      }
    },
    groupedFormKeys() {
      let groupedFormKeys = [];
      Object.keys(this.inputGroups).map((group) => {
        groupedFormKeys.push({
          label: this.tr(group, this.formLanguage),
          options: this.filterFormWithGroup(group).map((key) => ({
            value: key,
            text: this.tr(key, this.formLanguage),
          })),
        });
      });
      return groupedFormKeys;
    },
    async showTranscoControlModal(label, blabel, idx, text, blockIds, cls) {
      await this.documentEntityTranscoControl({ text, label, blabel });
      const suggestions = this.currentTranscoControl.suggestions;
      this.modalTranscoControlInfo = {
        blabel,
        idx,
        text,
        blockIds,
        isResult: false,
        class: cls,
      };
      if (
        (suggestions.length == 1 && suggestions[0].similarity >= 1.0) ||
        (suggestions.length > 1 &&
          suggestions[0].similarity >= 1.0 &&
          suggestions[1].similarity < 1.0)
      )
        this.selectTranscoControlSuggestion(suggestions[0].value);
      else this.$refs["control-modal"].show();
    },
    selectTranscoControlSuggestion(text) {
      this.modalTranscoControlInfo.isResult = true;
      if (text != null) this.modalTranscoControlInfo.text += ` [${text}]`;
      this.setFormNewValue(this.modalTranscoControlInfo);
      this.modalTranscoControlInputValue = "";
      this.$refs["control-modal"].hide();
    },
    documentsFilter(e) {
      if (this.documentsFilterValue == "All") return true;
      if (
        this.documentsFilterValue ==
        this.getDocumentStatus(e.textDetectionStatus, e.textEntitiesId)
      )
        return true;
      return false;
    },
    async pushResults() {
      const documentId = this.document.id;
      let url = await this.pushDocumentEntities({ documentId });
      if (url) {
        window.open(url, "_blank");
        await this.getDocuments();
      }
    },
    tr: Utils.tr,
    documentEntitySimilarityClass: contractUtils.documentEntitySimilarityClass,
  },
};
</script>

<style scoped lang="scss">
#split-panel {
  height: 90vh;
  overflow: hidden;
}
#split-panel-left {
  display: flex;
  .card {
    display: flex;
    flex-direction: column;
    flex: 1;
    .card-header {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
    }
    .card-body {
      overflow: scroll;
    }
  }
}
.document-entity-similarity-0-30 {
  color: #f05353;
}
.document-entity-similarity-30-80 {
  color: #f6a16c;
}
.document-entity-similarity-80-100 {
  color: #45a355;
}
</style>
