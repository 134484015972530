<template>
  <notifications
    class="cursor-pointer"
    width="400px"
    group="notification"
    position="bottom right"
    :max="4"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { NotificationDuration } from "@/const";
import Vue from "vue";

export default {
  computed: {
    ...mapGetters("notification", [
      "notificationId",
      "notificationType",
      "notificationMessage",
    ]),
  },
  watch: {
    $route() {
      this.resetState();
      this.$notify({
        group: "notification",
        clean: true,
      });
    },
    notificationId: function (val) {
      if (val !== 0) this.triggerNotification();
    },
  },
  methods: {
    ...mapMutations("notification", ["resetState"]),
    triggerNotification() {
      Vue.notify({
        id: this.notificationId,
        group: "notification",
        title: "",
        type: this.notificationType,
        text: this.notificationMessage,
        animationType: "velocity",
        duration: NotificationDuration,
        closeOncClick: true,
      })
    },
  },
};
</script>