import TokenService from "@/common/token.service";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

const ApiService = {

  post(resource, data, headers) {
    return axiosInstance.post(resource, data, {
      headers: headers
    });
  },

  put(resource, data, headers) {
    return axiosInstance.put(resource, data, {
      headers: headers
    });
  },

  get(resource, parameters) {
    return axiosInstance.get(resource, {
      params: parameters
    });
  }
};

const setupAxios = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (!["auth/login", "auth/refresh-token"].includes(originalConfig.url) && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            if (TokenService.getRefreshToken()) {
              const response = await axiosInstance.post("auth/refresh-token", 
                { refreshToken: TokenService.getRefreshToken() }, 
                { "Content-Type": "application/json" });

              TokenService.saveToken(response.data.access_token, response.data.refresh_token);

              return axiosInstance(originalConfig);
            }
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
}

setupAxios()

export default ApiService;
