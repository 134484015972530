import ApiService from '@/common/api.service'

const initialState = () => {
    return {
        messages: [],
    }
}

const state = initialState()

const getters = {
    messages: (state) => state.messages,
}

const actions = {
    async getMessages({ commit, dispatch }) {
        try {
            const response = await ApiService.get('messages', {})
            commit('setMessages', response.data)
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
}

const mutations = {
    setMessages(state, data) {
        state.messages = data.messages
    },
}

export const messages = {
    namespaced: true,
    initialState,
    state,
    getters,
    actions,
    mutations
}