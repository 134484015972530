import {
    contractLabelTr,
  } from "@/const";

export const Utils = {
    isEmptyDict: dict => Object.keys(dict).length === 0,
    pause: ms => new Promise(resolve => setTimeout(resolve, ms)),
    tr: (str, lang) => contractLabelTr[str] ? contractLabelTr[str][lang]: str
}

export const contractUtils = {
    reduceDocumentTextDetectionBlocks(textDetectionBlocks) {
        let reducedDocumentTextDetectionBlocks = []
        textDetectionBlocks.map((block) => {
            if (block.blockType == "WORD")
                reducedDocumentTextDetectionBlocks.push(
                    {
                        blockId: block.blockId,
                        page: block.page, 
                        w: block.geometry.boundingBox.width,
                        h: block.geometry.boundingBox.height,
                        t: block.geometry.boundingBox.top,
                        l: block.geometry.boundingBox.left,
                        text: block.text
                    }
                )
        })
        return reducedDocumentTextDetectionBlocks;
    },
    compareDocumentEntities: (entity1, entity2, blabel) => {
        let entity1MaxSimilarity = entity1.businessLabelsContextMaxSimilarity[blabel];
        let entity2MaxSimilarity = entity2.businessLabelsContextMaxSimilarity[blabel];
        if (entity1MaxSimilarity < entity2MaxSimilarity) return 1;
        if (entity1MaxSimilarity > entity2MaxSimilarity) return -1;
        return 0;
    },
    formStateToJson(form, numLayers) {
        let newValues = {}
        Object.keys(form).map((key) => {
            for (let i = 0; i < numLayers; i++) {
                if (form[key].newValue[i].text || form[key].newValue[i].isResult) {
                    if (!newValues[key]) newValues[key] = []
                    newValues[key].push(form[key].newValue[i])
                }
            }
        })
        return JSON.stringify({ newValues, numLayers })
    },
    formResultsToJson(form) {
        let results = {}
        Object.keys(form).map(
            (key) =>
              (results[key] = form[key].newValue
                .filter((nv) => nv.isResult)
                .map((nv) => nv.text))
          );
        return JSON.stringify(results);
    },
    documentEntitySimilarityClass(similarity) {
        if (similarity < 0.3) return "document-entity-similarity-0-30";
        if (similarity < 0.8) return "document-entity-similarity-30-80";
        return "document-entity-similarity-80-100";
    },
}