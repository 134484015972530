import TokenService from '@/common/token.service';
import ApiService from '@/common/api.service';
import queryString from 'query-string';

const initialState = () => {
  return {
    user: ''
  };
};

const state = initialState();

const getters = {
  user: state => state.user
};

const actions = {
  async login({ commit, dispatch }, { username, password }) {
    try {
      const response = await ApiService.post(
        'auth/login',
        queryString.stringify({ username, password }),
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      );
      commit('setUser', response.data);
      TokenService.saveToken(response.data.access_token, response.data.refresh_token);
    } catch (error) {
      const errorResponse = error.response;
      errorResponse.data.errors.forEach(error =>
        dispatch('notification/errorNotification', error.msg, { root: true }))
    }
  },
  async logout({ commit }) {
    TokenService.destroyToken();
    commit('resetStore', null, { root: true })
  },
  async isAuth({ commit }) {
    try {
      let response = await ApiService.get('auth/user');
      commit('setUser', response.data);
      return true;
    } catch (error) {
      return false;
    }
  }
};

const mutations = {
  setUser(state, user) {
    state.user = user.username;
  }
};

export const account = {
  namespaced: true,
  initialState,
  state,
  getters,
  actions,
  mutations
};
