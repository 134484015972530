import ApiService from '@/common/api.service'
import { contractUtils } from '@/common/utils'
import { contractFormSaved, documentUploadSuccessMessage, documentDeleteSuccessMessage } from "@/const";

const initialState = () => {
    return {
        documents: [],
        document: {
            id: '',
            url: '',
            name: '',
            textDetection: [],
            textDetectionStatus: '',
            textEntitiesId: ''
        },
        isProcessingDocument: false,
        isLoadingDocument: false,
        form: {},
        numLayers: 1,
        currentTranscoControl: {}
    }
}

const state = initialState()

const getters = {
    documents: (state) => state.documents,
    document: (state) => state.document,
    isProcessingDocument: (state) => state.isProcessingDocument,
    isLoadingDocument: (state) => state.isLoadingDocument,
    form: (state) => state.form,
    numLayers: (state) => state.numLayers,
    currentTranscoControl: (state) => state.currentTranscoControl
}

const actions = {
    async postDocument({ dispatch }, { formData }) {
        try {
            await ApiService.post('document', formData, { "Content-Type": "multipart/form-data" })
            dispatch('notification/successNotification', documentUploadSuccessMessage, { root: true })
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async getDocument({ commit, dispatch }, { documentId }) {
        try {
            commit('resetDocument')
            commit('resetForm')
            commit('setIsLoadingDocument', true)
            const response = await ApiService.get(`documents/${documentId}?signed_url=true`)
            const response2 = await ApiService.get('documents', {})
            if (response.data.textEntitiesId)
                await dispatch('getDocumentEntities', { documentId })
            commit('setDocuments', response2.data)
            commit('setDocument', response.data)
            commit('setIsLoadingDocument', false)
        } catch (error) {
            commit('setIsLoadingDocument', false)
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async getDocuments({ commit, dispatch }) {
        try {
            const response = await ApiService.get('documents', {})
            commit('setDocuments', response.data)
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async deleteDocument({ dispatch }, { documentId }) {
        try {
            await ApiService.post(`documents/${documentId}/delete`, {}, {})
            dispatch('notification/successNotification', documentDeleteSuccessMessage, { root: true })
            dispatch('getDocuments')
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async getDocumentEntities({ dispatch }, { documentId }) {
        try {
            const response = await ApiService.get(`text/entities?document_id=${documentId}`)
            dispatch('computeForm', { entities: response.data.entities, annotationState: response.data.annotationState, dataModel: response.data.dataModel })
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async putDocumentEntities({ dispatch }, { documentId }) {
        try {
            await ApiService.put(`text/entities?document_id=${documentId}`, contractUtils.formStateToJson(state.form, state.numLayers), { "Content-Type": "application/json" })
            dispatch('notification/successNotification', contractFormSaved, { root: true })
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async pushDocumentEntities({ dispatch }, { documentId }) {
        try {
            const response = await ApiService.post(`text/entities/push?document_id=${documentId}`, contractUtils.formResultsToJson(state.form), { "Content-Type": "application/json" })
            return response.data.url
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    async documentEntityTranscoControl({ commit, dispatch }, { text, label, blabel }) {
        try {
            const response = await ApiService.post(`text/entities/transco?text=${text}&label=${label}`, {}, {})
            const data = {"document": response.data, "businessLabel": blabel}
            const response2 = await ApiService.post('text/entities/control', JSON.stringify(data), { "Content-Type": "application/json" })
            commit('setCurrentTranscoControl', response2.data)
        } catch (error) {
            const errorResponse = error.response
            errorResponse.data.errors.forEach(error =>
                dispatch('notification/errorNotification', error.msg, { root: true }))
        }
    },
    computeForm({ commit }, { entities, annotationState, dataModel }) {
        const dm = dataModel.labels
        let form = {}
        dm.map((elem) => {
            const key = elem.businessLabel;
            form[key] = {}
            form[key].newValue = Array.from({ length: 12 }).map(() => ({
                text: "", blockIds: [], isResult: false, class: null
            }));
            form[key].isInputCollapsed = false;
            form[key].isInputCandidatesCollapsed = false;
            form[key].displayName = elem.displayName;
            form[key].group = elem.group;
            form[key].placeHolder = elem.placeHolder;
            form[key].blabelType = elem.type;
            form[key].label = elem.label;
            form[key].candidates = entities
                .filter((entity) => entity.label == form[key].label)
                .sort((entity1, entity2) =>
                    contractUtils.compareDocumentEntities(entity1, entity2, key)
                );
            if (annotationState && annotationState.newValues[key]) {
                form[key].newValue = annotationState.newValues[key].concat(
                    Array.from({ length: 12 - annotationState.newValues[key].length }).map(() => ({
                        text: "", blockIds: [], isResult: false, class: null
                    })));
            } else {
                if (form[key].candidates.length > 0 &&
                    form[key].candidates[0].businessLabelsContextMaxSimilarity[key] > 0.3) {
                    let transcoCandidate = form[key].candidates[0].transcoCandidate
                    form[key].newValue[0] = {
                        text: form[key].candidates[0].text + (transcoCandidate.similarity > 0.9 ? ` [${transcoCandidate.value}]`: ''),
                        blockIds: form[key].candidates[0].blockIds,
                        isResult: transcoCandidate.similarity > 0.9,
                        class: contractUtils.documentEntitySimilarityClass(
                            form[key].candidates[0].businessLabelsContextMaxSimilarity[key])
                    }
                }
            }
        });
        if (annotationState) commit('setNumLayers', annotationState.numLayers)
        commit('setForm', { form })
    }
}

const mutations = {
    setCurrentTranscoControl(state, data) {
        state.currentTranscoControl = data
    },
    setDocument(state, data) {
        state.document.id = data.documentId
        state.document.url = data.documentUrl
        state.document.name = data.objectName.split('/').slice(-1)[0]
        state.document.textDetection = data.textDetection ? contractUtils.reduceDocumentTextDetectionBlocks(data.textDetection.blocks) : []
        state.document.textEntitiesId = data.textEntitiesId ? data.textEntitiesId : ''
    },
    setDocuments(state, data) {
        state.documents = data.documents
    },
    resetDocument(state) {
        state.document = initialState().document
    },
    setIsProcessingDocument(state, data) {
        state.isProcessingDocument = data
    },
    setIsLoadingDocument(state, data) {
        state.isLoadingDocument = data
    },
    setForm(state, data) {
        state.form = data.form;
        state.form = { ...state.form };
    },
    setFormNewValue(state, data) {
        state.form[data.blabel].newValue[data.idx].text = data.text;
        state.form[data.blabel].newValue[data.idx].blockIds = data.blockIds;
        state.form[data.blabel].newValue[data.idx].isResult = data.isResult;
        state.form[data.blabel].newValue[data.idx].class = data.class;
    },
    setFormIsInputCollapsed(state, data) {
        state.form[data.blabel].isInputCollapsed = data.collapseState;
    },
    setNumLayers(state, data) {
        state.numLayers = parseInt(data);
    },
    resetForm(state) {
        state.form = initialState().form;
        state.form = { ...state.form };
        state.numLayers = initialState().numLayers;
    }
}

export const contract = {
    namespaced: true,
    initialState,
    state,
    getters,
    actions,
    mutations
}