<template>
  <b-container fluid>
    <b-row v-if="src" id="selected-blocks-header">
      <b-col lg="12">
        <div class="d-flex justify-content-end align-items-center px-4 pt-4">
          <span
            class="cursor-pointer"
            v-on:click.prevent.stop="zoomOut"
            :disabled="isDisabled"
            ><font-awesome-icon size="lg" icon="search-minus"
          /></span>
          <span
            class="pl-2 cursor-pointer"
            v-on:click.prevent.stop="zoomIn"
            :disabled="isDisabled"
            ><font-awesome-icon size="lg" icon="search-plus"
          /></span>
        </div>
        <transition name="fadeLeft" tag="ul" class="list-group">
          <li
            v-if="selectedBlocks.length > 0"
            sm="12"
            class="list-group-item border-0 bg-transparent px-4"
            style="animation-duration: 0.3s"
          >
            <b-badge
              v-for="(block, i) in selectedBlocks"
              class="mr-1 mb-1"
              :key="i"
              variant="dark"
              >{{ block.text }}</b-badge
            >
            <b-badge
              class="ml-2 mb-1 cursor-pointer"
              variant="primary"
              pill
              v-on:click="addSelectedBlocks"
              ><font-awesome-icon icon="plus"
            /></b-badge>
            <b-badge
              class="ml-2 mb-1 cursor-pointer"
              variant="primary"
              pill
              v-on:click="clearSelectedBlocks"
              ><font-awesome-icon icon="times"
            /></b-badge>
            <b-form-select
              class="w-25 ml-2"
              size="sm"
              :state="isSelectBlocksTagValid"
              v-model="selectedBlocksTag"
              :options="selectedBlocksTags"
            >
              <b-form-select-option :value="null"
                >Select an option</b-form-select-option
              >
            </b-form-select>
          </li>
        </transition>
      </b-col>
    </b-row>
    <b-row v-if="src && textLayerBlocks">
      <b-col
        v-for="page in numPages"
        :key="page"
        sm="12"
        :id="`pdf-viewer-page-${page}`"
        class="px-0"
      >
        <div class="position-absolute">
          <vuepdf
            v-bind="canvasAttrs[page - 1]"
            class="mb-3"
            :src="src"
            :page="page"
          ></vuepdf>
        </div>
        <div class="position-relative">
          <svg v-bind="canvasAttrs[page - 1]">
            <template v-for="(block, j) in pageBlocks[page]">
              <rect
                :id="`block-${block.blockId}`"
                class="block-rect cursor-pointer"
                :class="blockType(block)"
                :data-offset-top="
                  blockOffsetTop(page, block.t)
                "
                :key="`block-rect-` + j"
                v-on:click="selectBlock(block)"
                :x="block.l * 100 - 0.1 + '%'"
                :y="block.t * 100 - 0.1 + '%'"
                rx="2"
                :width="block.w * 100 + 0.2 + '%'"
                :height="block.h * 100 + 0.2 + '%'"
              />
              <rect
                class="block-inv-rect cursor-pointer"
                :class="`stroke-${blockType(block)}`"
                :key="'block-inv-rect-' + j"
                v-on:click="selectBlock(block)"
                :x="block.l * 100 - 0.1 + '%'"
                :y="block.t * 100 - 0.1 + '%'"
                :width="block.w * 100 + 0.2 + '%'"
                :height="block.h * 100 + 0.2 + '%'"
              />
              <text
                class="cursor-pointer"
                fill-opacity="0%"
                :key="'block-text-' + j"
                v-on:click="selectBlock(block)"
                :x="block.l * 100 - 0.1 + '%'"
                :y="(block.t + block.h) * 100 - 0.1 + '%'"
                :font-size="block.h*height[page-1]*0.5"
              >
                {{ block.text }}
              </text>
            </template>
          </svg>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import vuepdf from "vue-pdf";

const PixelRatio = window.devicePixelRatio || 1;
const ViewPortRatio = 0.98;

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    textLayerBlocks: {
      type: Array,
      required: true,
    },
    textLayerBlockType: {
      type: String,
      required: true,
    },
    selectedBlocksTags: {
      type: Array,
      required: true,
    },
  },
  components: {
    vuepdf,
  },
  data() {
    return {
      scale: 0,
      width: [],
      height: [],
      pixelWidth: [],
      pixelHeight: [],
      numPages: 0,
      selectedBlocks: [],
      isSelectBlocksTagValid: null,
      selectedBlocksTag: null,
    };
  },
  computed: {
    canvasAttrs() {
      let canvasAttrs = [];

      for (let i = 0; i < this.numPages; i++) {
        const [width, height] = [this.width[i], this.height[i]];
        const style = `width: ${this.pixelWidth[i]}px; height: ${this.pixelHeight[i]}px;`;

        canvasAttrs.push({ width, height, style });
      }
      return canvasAttrs;
    },
    pageBlocks() {
      let pageBlocks = {}
      this.textLayerBlocks.map(block => {
        if (!pageBlocks[block.page]) pageBlocks[block.page] = []
        pageBlocks[block.page].push(block)
      })
      return pageBlocks
    },
    isDisabled() {
      return !this.scale;
    },
  },
  watch: {
    selectedBlocksTag: function (newVal, oldVal) {
      if (!oldVal && newVal) this.isSelectBlocksTagValid = null;
    },
    textLayerBlockType: function () {
      this.selectedBlocks = [];
    },
    src: function () {
      this.calculateViewportSize();
      this.selectedBlocks = [];
    },
  },
  async mounted() {
    this.calculateViewportSize();
  },
  methods: {
    async calculateViewportSize() {
      if (!this.src) return;

      let { $el } = this;

      let pdf = await vuepdf.createLoadingTask(this.src).promise;

      let [w, h, pw, ph] = [[], [], [], []];

      for (let i = 1; i <= pdf.numPages; i++) {
        let page = await pdf.getPage(i);

        if (!this.scale) {
          let defaultViewport = page.getViewport({ scale: 1.0 });

          this.scale =
            ($el.clientWidth * PixelRatio * ViewPortRatio) /
            defaultViewport.width;
        }

        let viewport = page.getViewport({ scale: this.scale });

        w.push(Math.ceil(viewport.width));
        h.push(Math.ceil(viewport.height));
        pw.push(Math.ceil(viewport.width / PixelRatio));
        ph.push(Math.ceil(viewport.height / PixelRatio));
      }

      this.width = w;
      this.height = h;
      this.pixelWidth = pw;
      this.pixelHeight = ph;

      this.numPages = this.width.length;
    },
    blockType(block) {
      if (this.selectedBlocks.includes(block)) return "selected";
      return "empty";
    },
    selectBlock(block) {
      this.selectedBlocks.includes(block)
        ? this.selectedBlocks.splice(this.selectedBlocks.indexOf(block), 1)
        : this.selectedBlocks.push(block);
    },
    addSelectedBlocks() {
      if (!this.selectedBlocksTag) this.isSelectBlocksTagValid = false;
      if (this.selectedBlocks.length > 0 && this.selectedBlocksTag) {
        this.$emit(
          "onAddSelectedBlocks",
          this.selectedBlocks,
          this.selectedBlocksTag
        );
        this.selectedBlocks = [];
        this.selectedBlocksTag = null;
      }
    },
    clearSelectedBlocks() {
      this.selectedBlocks = [];
      this.selectedBlocksTag = null;
    },
    blockOffsetTop(page, blockHeightRatio) {
      return (
        this.pixelHeight
          .slice(0, page - 1)
          .reduce((partial_sum, a) => partial_sum + a, 0) +
        this.pixelHeight[page - 1] * blockHeightRatio
      );
    },
    zoomIn() {
      this.zoom(1.1);
    },
    zoomOut() {
      this.zoom(0.9);
    },
    zoom(scale) {
      let [w, h, pw, ph] = [[], [], [], []];
      for (let i = 0; i < this.width.length; i++) {
        w.push(this.width[i] * scale);
        h.push(this.height[i] * scale);
        pw.push(this.pixelWidth[i] * scale);
        ph.push(this.pixelHeight[i] * scale);
      }
      this.width = w;
      this.height = h;
      this.pixelWidth = pw;
      this.pixelHeight = ph;
    },
  },
};
</script>

<style lang="scss" scoped>
#selected-blocks-header {
  position: sticky;
  top: 0;
  z-index: 2;
}
.block-rect {
  stroke-width: 2;
  fill: none;
}
.block-inv-rect {
  fill: rgba(0, 0, 0, 0);
  stroke-width: 2;
}
.stroke-empty {
  stroke: rgba(120, 120, 120, 0.2);
}
.stroke-selected {
  stroke: rgba(120, 120, 120, 1);
}
</style>