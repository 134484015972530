const initialState = () => {
    return {
        notificationId: 0,
        notificationType: null,
        notificationMessage: null,
    }
}

const state = initialState()

const getters = {
    notificationId: (state) => state.notificationId,
    notificationType: (state) => state.notificationType,
    notificationMessage: (state) => state.notificationMessage
}

const actions = {
    successNotification({ commit }, message) {
        commit('success', message)
    },
    errorNotification({ commit }, message) {
        commit('error', message)
    }
}

const mutations = {
    success(state, message) {
        state.notificationId = state.notificationId + 1
        state.notificationType = 'success'
        state.notificationMessage = message
    },
    error(state, message) {
        state.notificationId = state.notificationId + 1
        state.notificationType = 'error'
        state.notificationMessage = message
    },
    resetState(state) {
        Object.assign(state, initialState())
    }
}

export const notification = {
    namespaced: true,
    initialState,
    state,
    getters,
    actions,
    mutations
}