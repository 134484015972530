import Vue from "vue";
import Router from "vue-router";
import Contract from "@/views/Contract.vue";
import Documents from "@/views/Documents.vue";
import Login from "@/views/Login.vue";
import store from "@/store";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Contract",
    component: Contract,
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
];

const router = new Router({
  base: "/",
  mode: "history",
  routes: routes
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired) {
    let isAuthenticated = await store.dispatch("account/isAuth");
    isAuthenticated ? next() : next("/login");
  } else {
    next();
  }
});

export default router;
