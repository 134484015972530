<template>
  <b-navbar
    id="nav"
    variant="white"
    class="px-4 py-3 border-bottom border-light"
  >
    <img id="logo" src="../assets/ccr.png" />
    <b-navbar-nav v-if="user" class="ml-auto">
      <b-nav-item-dropdown right no-caret>
        <template v-slot:button-content
          ><a
            ><b-avatar variant="primary" :text="user.charAt(0)"></b-avatar><font-awesome-icon class="ml-2" size="sm" icon="chevron-down" /></a
        ></template>
        <b-dropdown-item v-on:click="logout()" href="#">Logout</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    logout() {
      router.push({ name: "Login" });
    },
  },
};
</script>
<style scoped lang="scss">
#nav {
  z-index: 1030;
  height: 10vh;
  a {
    font-weight: bold;
    color: #2c3e50;
    // &.router-link-exact-active {
    //   color: #4974fb;
    // }
  }
}
#logo {
  max-height: 5vh;
}
</style>